const downloads = [
  { name: "Anonymous.7z", path: "/downloads/tools/python/Anonymous.7z" },
  {
    name: "Blue and Green Custom Icon v1",
    path: "/downloads/images/icons/sdf.png",
  },
  {
    name: "A Psychological Analysis of Adolf Hitler PDF",
    path: "/downloads/documents/pdf/A Psychological Analysis of Adolf Hitler.pdf",
  },
  {
    name: "CIA-RDP80-00810A000900750001-9",
    path: "/downloads/documents/pdf/CIA-RDP80-00810A000900750001-9.pdf",
  },
  {
    name: "CIA-RDP80T00246A012500090001-3",
    path: "/downloads/documents/pdf/CIA-RDP80T00246A012500090001-3.pdf",
  },
  {
    name: "Simple Sabotage Field Manual",
    path: "/downloads/documents/pdf/Simple Sabotage Field Manual.pdf",
  },
  {
    name: "Developer Safe Container Tarkov",
    path: "/downloads/models/fbx/developer_safecontainer.fbx",
  },
  {
    name: "item_barter_electr_tetriz",
    path: "/downloads/models/fbx/item_barter_electr_tetriz.fbx",
  },
  {
    name: "Item_barter_info_tgdocs",
    path: "/downloads/models/fbx/Item_barter_info_tgdocs.fbx",
  },
  {
    name: "item_container_trackcase",
    path: "/downloads/models/fbx/item_container_trackcase.fbx",
  },
  {
    name: "item_equipment_backpack_pilgrim",
    path: "/downloads/models/fbx/item_equipment_backpack_pilgrim.fbx",
  },
  {
    name: "item_equipment_helmet_altyn",
    path: "/downloads/models/fbx/item_equipment_helmet_altyn.fbx",
  },
  {
    name: "item_flash_card_ironkey",
    path: "/downloads/models/fbx/item_flash_card_ironkey.fbx",
  },
  {
    name: "item_money_bank_card",
    path: "/downloads/models/fbx/item_money_bank_card.fbx",
  },
  {
    name: "item_money_dollar_big",
    path: "/downloads/models/fbx/item_money_dollar_big.fbx",
  },
  {
    name: "item_plano_pistol_case",
    path: "/downloads/models/fbx/item_plano_pistol_case.fbx",
  },
  {
    name: "mag_stanag_colt_ar15_std_556x45_30",
    path: "/downloads/models/fbx/mag_stanag_colt_ar15_std_556x45_30.fbx",
  },
  {
    name: "soldiers-446",
    path: "/downloads/videos/war/soldiers-446.mp4",
  },
  {
    name: "war-46875",
    path: "/downloads/videos/war/war-46875.mp4",
  },
  {
    name: "videoplayback (2)",
    path: "/downloads/videos/war/videoplayback (2).mp4",
  },
  {
    name: "videoplayback (1)",
    path: "/downloads/videos/misc/videoplayback (1).mp4",
  },
  {
    name: "videoplayback",
    path: "/downloads/videos/misc/videoplayback.mp4",
  },
  {
    name: "Crowbar_2022-08-04_0.72",
    path: "/downloads/tools/exe/Crowbar_2022-08-04_0.72.7z",
  },
  {
    name: "vtfedit133",
    path: "/downloads/tools/exe/vtfedit133.exe",
  },
  {
    name: "7024781417175403270",
    path: "/downloads/audio/mp3/7024781417175403270.mp3",
  },
  {
    name: "7067887076347218693",
    path: "/downloads/audio/mp3/7067887076347218693.mp3",
  },
  {
    name: "c8896cc38afa4760bff0887ac7d072b1",
    path: "/downloads/audio/m4a/c8896cc38afa4760bff0887ac7d072b1.m4a",
  },
  {
    name: "Everything",
    path: "/downloads/tools/exe/Everything.exe",
  },
  {
    name: "7z2301-x64 (7-Zip)",
    path: "/downloads/tools/exe/7z2301-x64.exe",
  },
  {
    name: "AssetStudioGUI",
    path: "/downloads/tools/exe/AssetStudioGUI.exe",
  },
  {
    name: "creepy_setup_v1.4.1_x86_64",
    path: "/downloads/tools/exe/creepy_setup_v1.4.1_x86_64.exe",
  },
  {
    name: "innosetup-6.2.2",
    path: "/downloads/tools/exe/innosetup-6.2.2.exe",
  },
  {
    name: "lockhuntersetup_3-4-3",
    path: "/downloads/tools/exe/lockhuntersetup_3-4-3.exe",
  },
  {
    name: "npcap-1.76",
    path: "/downloads/tools/exe/npcap-1.76.exe",
  },
  {
    name: "nvm-setup",
    path: "/downloads/tools/exe/nvm-setup.exe",
  },
  {
    name: "OculusSetup",
    path: "/downloads/tools/exe/OculusSetup.exe",
  },
  {
    name: "processhacker-2.39-setup",
    path: "/downloads/tools/exe/processhacker-2.39-setup.exe",
  },
  {
    name: "Snort_2_9_20_Installer.x64",
    path: "/downloads/tools/exe/Snort_2_9_20_Installer.x64.exe",
  },
  {
    name: "TreeSizeFreeSetup",
    path: "/downloads/tools/exe/TreeSizeFreeSetup.exe",
  },
  {
    name: "videodownloadcapture-setup",
    path: "/downloads/tools/exe/videodownloadcapture-setup.exe",
  },
  {
    name: "Voice.ai-Downloader-alphaver-99b6272eaa374395b45c0d84c08eddf6",
    path: "/downloads/tools/exe/Voice.ai-Downloader-alphaver-99b6272eaa374395b45c0d84c08eddf6.exe",
  },
  {
    name: "wget-1.11.4-1-setup",
    path: "/downloads/tools/exe/wget-1.11.4-1-setup.exe",
  },
  {
    name: "winsdksetup",
    path: "/downloads/tools/exe/winsdksetup.exe",
  },
  {
    name: "Ezekiel - Help Urself Slowed + Intro What’s Wrong, Scared",
    path: "/downloads/audio/mp3/Ezekiel - Help Urself Slowed + Intro What’s Wrong, Scared.mp3",
  },
  {
    name: "Ghostface Playa - Why Not (slowed + reverb)",
    path: "/downloads/audio/mp3/Ghostface Playa - Why Not (slowed + reverb).mp3",
  },
  {
    name: "WinRAR Registery Key",
    path: "/downloads/key/rarreg.key",
  },
  {
    name: "Microsoft Office 2010 Professional Plus 32-bit",
    path: "https://archive.org/download/sw-dvd-5-office-professional-plus-2010-w-32-english-mlf-x-16-52536/SW_DVD5_Office_Professional_Plus_2010_W32_English_MLF_X16-52536.ISO",
  },
  {
    name: "DAEMON Tools 3.47",
    path: "https://archive.org/download/daemon-tools-347/daemon347.exe",
  },
  {
    name: "Autodesk AutoCAD 2000(Autodesk)(1999)(00120 016008 0011)",
    path: "https://archive.org/download/Autodesk_AutoCAD_2000_Autodesk_1999_00120-016008-0011/Autodesk%20AutoCAD%202000%28Autodesk%29%281999%29%2800120-016008-0011%29.ISO",
  },
  {
    name: "LEGO Star Wars: The Video Game (2005)",
    path: "https://archive.org/download/eidos-legostarwarsthevideogame-2005/LEGOSTARWARS.iso",
  },
  {
    name: "Microsoft SQL Server 2008 R2 Enterprise",
    path: "https://archive.org/download/MSSQL2008R2/MSSQL2008R2.iso",
  },
  {
    name: "Adobe Acrobat 9 Pro Extended for Windows (Adobe)(90086426 4 08 na)(2008)",
    path: "https://archive.org/download/Adobe_Acrobat_9_Pro_Extended_for_Windows_Adobe_90086426_4-08_na_2008/Adobe%20Acrobat%209%20Pro%20Extended%20for%20Windows%20%28Adobe%29%2890086426%204-08%20na%29%282008%29.iso",
  },
  {
    name: "Halo 2",
    path: "https://archive.org/download/halo-2_202103/halo-2_202103_archive.torrent",
  },
  {
    name: "AutoCAD Civil 3D   Land Desktop Companion 2009 (Autodesk)(237A1 05A001 P303A)(2008)",
    path: "https://archive.org/download/AutoCAD_Civil_3D_Land_Desktop_Companion_2009_Autodesk_237A1-05A001-P303A_2008/AutoCAD%20Civil%203D%20-%20Land%20Desktop%20Companion%202009%20%28Autodesk%29%28237A1-05A001-P303A%29%282008%29.ISO",
  },
  {
    name: "Microsoft Visual Studio 2005 Express Edition",
    path: "https://archive.org/download/mvs-2005-ee/VS2005EE.iso",
  },
  {
    name: "MS Office 2008 Home And Student Edition 2008 for Mac",
    path: "https://archive.org/download/ms-office-2008-home-and-student-edition-2008/MS_Office_2008_Home_And_Student_Edition_2008.iso",
  },
  {
    name: "Adobe Photoshop CS2",
    path: "https://archive.org/download/adobe-photoshop-cs2/ADOBE%20PHOTOSHOP%20CS2.iso",
  },
  {
    name: "Call of Duty 4 Modern Warfare 2007 Activision DVD",
    path: "https://archive.org/download/call-of-duty-4-modern-warfare-2007-activision-dvd_202109/Call.of.Duty.4.Modern.Warfare.2007.Activision.DVD.7z",
  },
  {
    name: "softwareAdobe Acrobat 8 Professional (2006-08) [English] (CD)",
    path: "https://archive.org/download/adobe-acrobat-8-professional-2006-08-english-cd/Disc01.iso",
  },
  {
    name: "Windows Movie Maker 6.0 HD",
    path: "https://archive.org/download/windows-movie-maker-6.0_202101/Profiles.zip",
  },
  {
    name: "Microsoft Visual Basic 6.0 Professional Edition (January 2001)(203 01158)(X06 15978)",
    path: "https://archive.org/download/Microsoft_Visual_Basic_6.0_Professional_Edition_January_2001_203-01158_X06-15978/Microsoft%20Visual%20Basic%206.0%20Professional%20Edition%20%28January%202001%29%28203-01158%29%28X06-15978%29.ISO",
  },
  {
    name: "Microsoft Visual Studio 2005-2015 Professional Edition collection 8 files",
    path: "https://archive.org/compress/dev-microsoft-visual-studio-2005-2015-Pro/formats=ISO%20IMAGE&file=/dev-microsoft-visual-studio-2005-2015-Pro.zip",
  },
  {
    name: "Reason 5.0.1",
    path: "https://archive.org/download/reason-5.0.1/Reason%205.0.1.iso",
  },
  {
    name: "Adobe PageMaker 6.5",
    path: "https://archive.org/download/adobe-pagemaker-6.5/Adobe%20Pagemaker%206.5%20PAGEMAKR65.ISO",
  },
  {
    name: "อิสราเอลพร้อมบุกฉนวนกาซาและกวาดต้อนนักรบ Hamas ทั้งหมด - The Daily Dose",
    path: "https://archive.org/download/youtube-0JT8JlLKw4A/0JT8JlLKw4A.mp4",
  },
  {
    name: "ISRAEL DESTROYS HAMAS BUILDING",
    path: "https://seed191.bitchute.com/Whuei2DGmgBn/qNnXXF2rbGxR.mp4",
  },
  {
    name: "HAMAS FIRING FROM RESIDENTIAL AREA",
    path: "https://seed191.bitchute.com/vBEqxcyTQvca/ZuweZASq8lB3.mp4",
  },
  {
    name: "ENG SUB Weapons in our hands, the call of a brave heart moves us forward",
    path: "https://player.odycdn.com/v6/streams/9f4215fa2fa01f5058daaaed1584d1fd126b607d/119a5d.mp4",
  },
  {
    name: "Ru Troops Suffering Radiation Sickness After Fishing & Digging Trenches Near Chernobyl, UAF fired St",
    path: "https://player.odycdn.com/v6/streams/806fb1b8ac54c206900813daf5cb33dcc43b5c59/0493a0.mp4",
  },

  //   {
  //     name: "",
  //     path: "",
  //   },
];

export default downloads;
