import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/modules/global";
import { Home } from "./components/pages";

import "./styles/global/global.scss";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
