import React, { useState } from "react";
import downloads from "../../../data/downloads";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const sortedDownloads = [...downloads].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const filteredDownloads = sortedDownloads.filter((download) =>
    download.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="home">
      <h1 className="title">Downloads</h1>
      <h2>
        I believe information should be public, so here are many different files
        that I have gathered.
      </h2>

      <div className="filter">
        <input
          type="text"
          placeholder="Search downloads"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="downloads">
        {filteredDownloads.map((download, index) => (
          <a key={index} href={download.path} download>
            {download.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Home;
